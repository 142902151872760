import { useDispatch, useSelector } from "react-redux";
import { changeCurrent, selectChilds, selectCurrentChild } from "../store/user";

const ChildSelect = () => {
    const childs = useSelector(selectChilds);
    const curentChild = useSelector(selectCurrentChild);
    const dispatch = useDispatch();

    const changeCurChilder = (data) => {
        const curChild = {
            name: data.name,
            contract: data.contract
        }
        dispatch(changeCurrent(curChild))
    }

    return(
        <>
            {childs.length > 1 && 
                <div className="lessons__child-select">
                {childs.map(el=>{
                    return (
                    <div key={el._id}>
                        <input name="curChild" id={el._id} type={"radio"} defaultChecked={el.contract === curentChild.contract} className="" onClick={()=>{changeCurChilder(el)}} />
                        <label htmlFor={el._id} >{el.name}</label>
                    </div>)                
                })}
                </div>
            }   
        </>
    )
}

export default ChildSelect