import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import $api from '../service/api';

let isLoggedIn = localStorage.getItem('token') ? true : false

const initialState = {
    isLoading: false,
    isLoggedIn: isLoggedIn,
    name: "",
    id: "",
    isAdmin: false,
    childs: [],
    errorsOnLogin: "",
    email: "",
    canPayByCard: false,
    showBalance: false,
    currentChild: {
        name: "",
        contract: "",
        balance: ""
    }
  }

export const userLogin = createAsyncThunk(
    'user/login',
    async (formData, thunkAPI) => {
        try{
            const response = await $api.post('/auth/login', formData);
            return response.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error.response.status);
          }
    }
)

export const userRefresh = createAsyncThunk(
    'user/refresh',
    async (_, thunkAPI) => {
        try{
            const response = await $api.get('/auth/refresh');
            return response.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error);
          }
    }
)

export const getBalance = createAsyncThunk(
    'user/balance',
    async (contract, thunkAPI) => {
        try{
            const response = await $api.get('/user/balance/'+contract);
            return response.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error);
          }
    }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthorized: (state, action) => {
        state.name = action.payload.user.userName;
        state.id = action.payload.user.userID;
        state.isAdmin = action.payload.user.isAdmin;
        state.isLoggedIn = true;
        state.isLoading = false;
        state.childs = action.payload.user.childs;
        state.email = action.payload.user.email;
        state.currentChild = {
            name: action.payload.user.childs[0].name,
            contract: action.payload.user.childs[0].contract
        }
        state.canPayByCard = action.payload.user.canPayByCard;
        state.showBalance = action.payload.user.showBalance;

        localStorage.setItem('token', action.payload.token || action.payload.accessToken);
    },
    setUnauthorized: (state ) => {
        localStorage.clear();
        state.isLoggedIn = false;
        state.name = "";
        state.id = "";
        state.childs = []
        state.isAdmin = false;
        localStorage.clear();
    },
    setLoading: (state, action) => {
        state.isLoading = action.payload;
    },
    changeCurrent: (state, action) => {
        state.currentChild = {...state.currentChild, ...action.payload} 
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(userLogin.fulfilled, (state, action) => {
            state.name = action.payload.userName;
            state.id = action.payload.userID;
            state.isAdmin = action.payload.isAdmin;
            state.childs = action.payload.childs
            state.isLoggedIn = true;
            state.isLoading = false;
            state.errorsOnLogin = "";
            state.email = action.payload.email;
            state.canPayByCard = action.payload.canPayByCard;
            state.showBalance = action.payload.showBalance;
            state.currentChild = {
                name: action.payload?.childs[0].name,
                contract: action.payload?.childs[0].contract
            }

            localStorage.setItem('token', action.payload.token);
        })
        .addCase(userRefresh.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(userLogin.rejected, (state, action) => {
            if(action.payload === 404) {
                state.errorsOnLogin = "Такой email не найден"
            }
            if(action.payload === 403) {
                state.errorsOnLogin = "Неверный пароль :("
            }
        })
        .addCase(userRefresh.fulfilled, (state, action) => {
            state.name = action.payload.userName;
            state.id = action.payload.userID;
            state.isAdmin = action.payload.isAdmin;
            state.childs = action.payload.childs;
            state.canPayByCard = action.payload.canPayByCard;
            state.showBalance = action.payload.showBalance;
            state.isLoggedIn = true;
            state.isLoading = false;
            state.currentChild = {
                name: action.payload.childs[0].name,
                contract: action.payload.childs[0].contract
            }

            localStorage.setItem('token', action.payload.token);
        })
        .addCase(getBalance.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getBalance.fulfilled, (state, action) => {
            state.currentChild.balance = action.payload.sum
            state.isLoading = false;
        })
  }
})

export const { setAuthorized, setUnauthorized, setLoading, setUser, changeCurrent } = userSlice.actions

export const selectAuth = (state) => state.user.isLoggedIn;
export const isChildLoaded = (state) => !state.user.isLoggedIn || state.user.childs.length > 0;
export const selectLoading = (state) => state.user.isLoading;
export const selectContract = (state) => state.user.childs.map(el=>el.contract);
export const selectError = (state) => state.user.errorsOnLogin;
export const selectEmail = (state) => state.user.email;
export const selectName = (state) => state.user.name.split(' ')[0];
export const selectChilds = (state) => state.user.childs;
export const selectCurrentChild = (state) => state.user.currentChild;
export const selectBalance = (state) => state.user.currentChild.balance;
export const selectCanPayByCard = (state) => state.user?.canPayByCard;
export const selectShowBalance = (state) => state.user?.showBalance;
export const selectRecomendSum = (state) => state.user?.childs.filter(el=>el.contract===state.user.currentChild.contract)[0].recomendSum

export default userSlice.reducer