import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChildSelect from "../components/childSelect";
import { fetchReviews, selectReviews } from "../store/review";
import { selectCurrentChild } from "../store/user";
import dateConverter from '../utils/dataConverter';

const ReviewPage = () => {
    const dispatch = useDispatch();
    const reviews = useSelector(selectReviews);
    const curentChild = useSelector(selectCurrentChild);

    useEffect(()=>{
        dispatch(fetchReviews());
    },[dispatch])

    return (
        <main className="container review-page__main">
            <h1 className="main-headline">Успехи</h1>
            <ChildSelect />
            {reviews.length>0 ? reviews.map(el=>{
                return (
                    <div className="review__item" key={el._id}>
                        <h3 className="review-item__headline">Отзыв от {dateConverter(el.date)}</h3>
                        <p className="review-item__student">Студент: {el.student.name}</p>
                        <p className="review-item__student review-item__student--course">Курс: {el.courseProgress?.courseName}</p>
                        <p className="review-item__comment">{el.comment}</p>
                        <p className="review__author"><b>Тьютор: </b>{el.teacher.userName}</p>
                        { el.file && 
                            <div className="review__file-wrap">
                                <a className="review__file-link" target="_blank" rel="noreferrer" href={"http://smash.yescoding.ru"+el.file}>Посмотреть вложение</a>
                            </div>
                        }
                    </div>
                )
            })
            :
            <p>{curentChild.name} еще не получил(-а) отзыв от перподавателя. Еще пару уроков и отзыв точно появится 😉</p>
        }
        </main>
    )
}

export default ReviewPage;