import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { getBalance, selectAuth, selectBalance, selectCurrentChild, selectShowBalance, setUnauthorized } from "../store/user";
import mainIcon from '../img/maincomponent.svg';
import moneyIcon from '../img/dollarsquare.svg';
import successIcon from '../img/medalstar.svg';
import lessonsIcon from '../img/calendartick.svg';
import offIcon from '../img/off_icon.svg';
import { useEffect } from "react";

const Navbar = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector(selectAuth);
    const location = useLocation();
    const balance = useSelector(selectBalance);
    const currentChild = useSelector(selectCurrentChild);
    const showBalance = useSelector(selectShowBalance);
    
    useEffect(()=>{
        dispatch(getBalance(currentChild.contract))
    },[dispatch, currentChild.contract])

    return (
        <div className="container ">
                {isAuth && 
                <div className="nav-bar__exit-wrap">
                    {showBalance && 
                        <NavLink
                            to={'/balance'} 
                            className={({isActive}) => 
                                isActive ?
                                "nav-bar__balance-wrap nav-bar__balance-wrap--active" :
                                "nav-bar__balance-wrap "+ (balance<0 ? "nav-bar__balance-wrap--negative":"")
                            }>
                            {balance} ₽</NavLink>
                    }
                    <button className="nav-bar__exit-btn" onClick={()=>dispatch(setUnauthorized())}>
                        <img src={offIcon} alt="Выход"/>
                    </button>
                </div>}
                    
            {location.pathname !== '/' &&
            <nav className="main-nav">
                    <ul className="nav-link__list">
                        <li className="nav-list__item">
                            <NavLink
                            className={({isActive}) => 
                                isActive ? "nav-list__item-link nav-list__item-link--active" : "nav-list__item-link"
                            }
                             to={'/'}>
                                <img alt="Главная" src={mainIcon} />
                            </NavLink>
                        </li>
                        <li className="nav-list__item">
                            <NavLink 
                                className={({isActive}) => 
                                    isActive ? "nav-list__item-link nav-list__item-link--active" : "nav-list__item-link"
                                }
                                to={'/payment'}>
                                <img alt="Оплата" src={moneyIcon} />
                            </NavLink>
                        </li>
                        <li className="nav-list__item">
                            <NavLink 
                                className={({isActive}) => 
                                    isActive ? "nav-list__item-link nav-list__item-link--active" : "nav-list__item-link"
                                }
                                to={'/reviews'}>
                                <img alt="Успехи" src={successIcon} />
                            </NavLink>
                        </li>
                        <li className="nav-list__item">
                            <NavLink 
                                className={({isActive}) => 
                                    isActive ? "nav-list__item-link nav-list__item-link--active" : "nav-list__item-link"
                                }
                                to={'/lessons'}>
                                <img alt="Уроки" src={lessonsIcon} />
                            </NavLink>
                        </li>
                    </ul>
            </nav>
                } 
        </div>
        )
}

export default Navbar;