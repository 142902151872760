import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    lessons: [],
    isLoading: false,
  }

export const fetchLessons = createAsyncThunk(
    'lessons/fetch',
    async (_, thunkAPI) => {
        const contract = thunkAPI.getState().user.childs.map(el=>el.contract);
        try{
          const url = `https://smash.yescoding.ru/api/attendances/bycontract/[${ contract.join(',%20') }]`
            const response = 
              await axios.get(url, {mode: 'cors', credentials: 'include'})
            return response.data;
          }
          catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
          }
    }
)

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchLessons.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchLessons.fulfilled, (state, action) => {
            state.lessons = action.payload.reverse() || []
        })
        
  }
})

export const selectLessons = ({ lessons, user }) => lessons.lessons.filter(el=>el.studentId.contract === user.currentChild.contract);

export default lessonsSlice.reducer