import { Provider } from "react-redux";
import store from "./store";

import AppRouter from "./route";
import UserChecker from "./components/userChecker";

function App() {
  return (
    <Provider store={store}>
      <UserChecker>
        <AppRouter />
      </UserChecker>
    </Provider>
  );
}

export default App;
