import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    reviews: [],
    isLoading: false
  }

export const fetchReviews = createAsyncThunk(
    'review/fetch',
    async (_, thunkAPI) => {
      const contract = thunkAPI.getState().user.childs.map(el=>el.contract);
      const url = `https://smash.yescoding.ru/api/review/contract/[${ contract.join(',%20') }]`

        try{
            const response = await axios.get(url)
            return response.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
          }
    }
)

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchReviews.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchReviews.fulfilled, (state, action) => {
            state.reviews = action.payload || []
        })
        
  }
})

export const selectReviews = (state) => state.review.reviews.filter(el=>el.student.contract === state.user.currentChild.contract);


export default reviewSlice.reducer