import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectName } from "../store/user";
import greenArrow from "../img/main_arrow.svg"

const MainPage = () => {
    const userName = useSelector(selectName);

    return (
        <main className="container">
            <h1 className="main-headline">Здравствуйте,<br/>{userName}</h1>
            <section className="main-page__wrap">
                <ul className="main-page__link-list">
                    <li>
                        <NavLink className={"main-page__link-item"} to={'/lessons'}>
                            <div>
                                <h3 className="main-page__link-headline">Уроки</h3>
                                <p className="main-page__link-description">
                                    Список занятий, даты и посещенных занятий
                                </p>
                            </div>
                            <div className="arrow__wrap">
                                <img alt="Зеленая страелка" src={greenArrow} />
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={"main-page__link-item"} to={'/reviews'}>
                            <div>
                                <h3 className="main-page__link-headline">Успехи</h3>
                                <p className="main-page__link-description">
                                    Отзывы преподавателя, результаты тестов и дипломы
                                </p>
                            </div>
                            <div className="arrow__wrap">
                                <img alt="Зеленая страелка" src={greenArrow} />
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={"main-page__link-item"} to={'/payment'}>
                            <div>
                                <h3 className="main-page__link-headline">Оплата</h3>
                                <p className="main-page__link-description">
                                    Удобный способ оплатить занятия
                                </p>
                            </div>
                            <div className="arrow__wrap">
                                <img alt="Зеленая страелка" src={greenArrow} />
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </section>
        </main>
    )
}

export default MainPage;