import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import reviewReducer from './review';
import lessonsReducer from './lessons';
import transactionsReducer from './transactions';
import scheduleReducer from './schedule';

const store = configureStore({
    reducer: {
        user: userReducer,
        review: reviewReducer,
        lessons: lessonsReducer,
        transactions: transactionsReducer,
        schedule: scheduleReducer
    }
})

export default store;