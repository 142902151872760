import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectAuth, selectError, userLogin } from "../store/user";

const AuthPage = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector(selectAuth);
    const error = useSelector(selectError);
    
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    })

    const submitHandler = (evt) => {
        evt.preventDefault();
        dispatch(userLogin(formData));
    }

    const changeValueHandler = ({target}) => {
        setFormData(p=>{return {...p ,[target.name]: target.value}});
    }
    
    if( isAuth ) {
        return <Navigate to='/' replace/>
    }

    return(
        <main className="auth-page__main">
            <div className="container">
                <h1 className="main-headline">Дайвайте авторизуемся</h1>
                <p className="auth-page__description">
                Логин и пароль приходил к вам на почту. 
                Если не можете вспомнить, обратитесь к менеджеру, мы поможем
                </p>
                <form onSubmit={submitHandler}>
                    <p className="auth-forn__error-wrap">
                    {error}
                    </p>
                    <input className="auth-form__text-input" placeholder="Email" type="email" value={formData.email} onChange={changeValueHandler} name="email" required />
                    <input className="auth-form__text-input" placeholder="Пароль" type="password" value={formData.password} onChange={changeValueHandler} name="password" required />
                    <div className="submit-btn__wrap">
                        <input className="auth-form__submit-btn" type="submit" key="Enter" value="Войти в личный кабинет" />
                    </div>
                </form>
            </div> 
        </main>
    )
}

export default AuthPage;