import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchSchedule, selectScheduleDays } from "../store/schedule";
import { translateDayOfWeek } from "../uitls/dayOfWeekTranslater";
import { selectCurrentChild } from "../store/user";

const Schedule = () => {
    const dispatch = useDispatch();
    const days = useSelector(selectScheduleDays);
    const contract = useSelector(selectCurrentChild).contract;

    useEffect(()=>{
        dispatch(fetchSchedule(contract))
    },[dispatch,contract])

    return(
        <div className="schedule__wrap">
            {days && days.map(el=>(
                <div className="schedule-item__wrap" key={el._id}>
                    <div className="schedule-header block-header">
                        <h3 className="lesson-item__date">{el.group?.progress[0].courseName}</h3>
                    </div>
                    <div className="lesson-item__footer">       
                        <div className="lesson-footer__course schedule-course">
                            {el.days && el.days.map(day=>(
                                <div className="schedule__day-wrap" key={day._id}>
                                    <b>{translateDayOfWeek(day.dayOfWeek)}</b> {day.time.startTime} – {day.time.endTime}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Schedule