import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../service/api";

const initialState = {
    list: [],
    isLoading: false
  }

export const fetchTransactions = createAsyncThunk(
    'transactions/fetch',
    async (contract, thunkAPI) => {
        const url = `/user/transactions/${contract}`;

        try{
            const response = await $api.get(url)
            return response.data;
        }
        catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
            }
    }
)

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchTransactions.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchTransactions.fulfilled, (state, action) => {
            state.list = action.payload || []
        })
        
  }
})

export const selectTransactions = (state) => state.transactions.list;


export default transactionsSlice.reducer