import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLessons, selectLessons } from "../../store/lessons";
import dateConverter from "../../utils/dataConverter";
import goodClock from '../../img/clock-good.svg';
import badClock from '../../img/clock-bad.svg';  
import ChildSelect from "../../components/childSelect";
import Schedule from "../../components/schedule";

const LessonsPage = () => {
    const lessons = useSelector(selectLessons);
    const dispatch = useDispatch();


    useEffect(()=>{
        dispatch(fetchLessons());
    },[dispatch])

    return (
        <main className="container lessons-page">
            <h1 className="main-headline">Уроки</h1>
            <ChildSelect />
            <Schedule />
            {lessons && lessons.map(el=>{
                return (
                    <div className="lesson-list__item" key={el._id}>
                        <div className="lesson-item__header block-header">
                            <b className="lesson-item__date">{dateConverter(el.lessonId.date)} </b>
                            { el.presence ?
                            <div className="lesson-item__clock-img-wrap">
                                <div className="clock__wrap">
                                    <img src={goodClock} alt="длительность урок" />
                                </div>
                                <span>{el.isAcademic ? el.lessonId.hours * 40 : el.lessonId.hours * 60} минут</span>
                            </div> 
                            :
                            <div className="lesson-item__clock-img-wrap">
                                <div className="clock__wrap">
                                    <img src={badClock} alt="длительность урок" />
                                </div>
                                <span>Пропуск</span>
                            </div>
                            }
                            {el.bySub ? 
                                <p className={"lesson-item__price "+ (!el.presence ? "lesson-item__price--bad" : "") }>Абонемент</p> 
                                :
                                <p className={"lesson-item__price "+ (!el.presence ? "lesson-item__price--bad" : "") }>{ el.price } р.</p>
                            }
                        </div>
                        <div className="lesson-item__footer">
                            
                            <p className="lesson-footer__course">
                                <b>Курс: </b>
                                {el.lessonId.progress?.courseName}
                            </p>
                            <p>{el.studentId?.name.split(' ')[1]}</p>
                        </div>
                    </div>)
            })}
        </main>
    )
}

export default LessonsPage;