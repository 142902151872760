import { useSelector } from 'react-redux';
import ChildSelect from '../../components/childSelect';
import { selectCanPayByCard } from '../../store/user';
import Sbp from './include/spb';

const PaymentPage = () => {
    const canPayByCard = useSelector(selectCanPayByCard);

    return (
        <main className="container payment-page">
            <h1 className="main-headline">Оплата обучения</h1>
            <ChildSelect />
            {canPayByCard && <Sbp key={'by_card_payment'} headline='По карте' type='BY_CARD'/>}
            <Sbp key={'spb_payment'} headline='СБП' type='SBP'/>
        </main>
    )
}

export default PaymentPage;