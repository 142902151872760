import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isChildLoaded, setAuthorized, setUnauthorized } from "../store/user";
import useFetch from '../hooks/useFetch';

const UserChecker = ({ children }) => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const [{ response }, doFetch] = useFetch('/auth/refresh');
    const isLoaded = useSelector(isChildLoaded);

    useEffect(() => {
        if (!token) {
          dispatch(setUnauthorized());
          return;
        }

        doFetch();
      }, [dispatch, token, doFetch])

    useEffect(()=>{
      if(!response) {
        return;
      }

        dispatch(setAuthorized(response));
    },[response, dispatch])

    if(!isLoaded) {
      return null
    }

    return children;
}

export default UserChecker;