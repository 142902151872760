import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/navbar';
import AuthPage from './pages/auth-page';
import LessonsPage from './pages/lessons-page';
import MainPage from './pages/main-page';
import PaymentPage from './pages/payment-page';
import ReviewPage from './pages/review-page';
import { selectAuth, selectShowBalance } from './store/user';
import BalancePage from './pages/balance-page';


function AppRouter() {
    const isAuth = useSelector(selectAuth);
    const showBalance = useSelector(selectShowBalance);
    return(
        <BrowserRouter>
            {isAuth && <Navbar />}
            <Routes>
                <Route path='/' element={
                    <MainPageProtected />
                } />
                <Route path='/reviews' element={
                    <AuthProtected>
                        <ReviewPage />
                    </AuthProtected>
                } />
                <Route path='/payment' element={
                    <AuthProtected>
                        <PaymentPage />
                    </AuthProtected>
                } />
                <Route path='/balance' element={
                    <AuthProtected>
                        {showBalance &&
                            <BalancePage /> 
                        }
                    </AuthProtected>
                } />
                <Route path='/lessons' element={
                    <AuthProtected>
                        <LessonsPage />
                    </AuthProtected>
                } />
                <Route path='/auth' element={ <AuthPage/> } />
                <Route path='*' element={<MainPageProtected />} />
            </Routes>
        </BrowserRouter>
    )
}

function MainPageProtected() {
    return(
        <AuthProtected>
            <MainPage />
        </AuthProtected>
    )
}

function AuthProtected({ children }) {
    const location = useLocation();
    const isAuth = useSelector(selectAuth);

    if(isAuth){
        return children;
    }
    return <Navigate to={'/auth'} state={{ from: location }} replace={true} />
}


export default AppRouter;