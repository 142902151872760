import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    days: [],
    isLoading: false,
  }

export const fetchSchedule = createAsyncThunk(
    'schedule/fetch',
    async (contract, thunkAPI) => {
        try{
          const url = `https://smash.yescoding.ru/api/schedule/student-by-contract?contract=${contract}&token=dhaps222AsdI$`
            const response = 
              await axios.get(url, {mode: 'cors', credentials: 'include'})
            return response.data;
          }
          catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
          }
    }
)

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchSchedule.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchSchedule.fulfilled, (state, action) => {
            state.days = action.payload.reverse() || []
        })
        
  }
})

export const selectScheduleDays = (state) => state.schedule?.days;

export default scheduleSlice.reducer