import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import $api from "../../../service/api";
import { selectCurrentChild, selectEmail, selectRecomendSum } from "../../../store/user";

const Sbp = ({ headline="CБП",  type="SBP"}) => {
    const [customSum, setCustomSum] = useState("");
    const contract = useSelector(selectCurrentChild).contract;
    const email = useSelector(selectEmail);
    const [isLoading, setIsLoading] = useState(false);
    const recomendSum = useSelector(selectRecomendSum);
    const [payAmount, setPayAmount] = useState(recomendSum);

    useEffect(()=>{
        setPayAmount(recomendSum)
    },[recomendSum])

    const submitHandler = (evt) => {
        evt.preventDefault();
        setIsLoading(true);
        const sumToPay = payAmount === 'custom' ? customSum : payAmount;
        const formObjects = {};
        formObjects.amount = sumToPay;
        formObjects.dogovor = contract;
        formObjects.Email = email;

        switch (type) {
            case 'SBP':
                (async ()=>{
                    await $api.post('/payment/generate-qr',
                    {
                        "contract": formObjects.dogovor,
                        "email": formObjects.Email,
                        "amount": formObjects.amount * 100
                    }).then(response => {
                        if(response.status === 200) {
                            document.location.href = response.data.Data;
                        }
                    })
                })();
                break;
            case 'BY_CARD':
                (async ()=>{
                    await $api.post('/payment/generate',
                    {
                        "contract": formObjects.dogovor,
                        "email": formObjects.Email,
                        "amount": formObjects.amount * 100
                    }).then(response => {
                        if(response.status === 200) {
                            document.location.href = response.data.url;
                        }
                    })
                })();
                break;
        
            default:
                console.log('Unknown type')
                break;
        }

        return setIsLoading(false)
    }

    const onRadioSelect = (e) => {
        const { target } = e;
        setPayAmount(target.value);
    }

    const customSumChangeHandler = ({target}) => {
        setCustomSum(target.value)
    }

    return(
        <form className='payment__sbp-form' onSubmit={submitHandler}>
            <div className='payment-form__header-wrap'>
                <h2 className='payment-form__headline'>{ headline }
                { type === "SBP" &&
                    <span className='payment-form__description'> (qr-код)</span>
                }
                </h2>
                <div className='payment__amount-variant'>
                    <div className='amout-radio__wrap'>
                        <input type="radio" 
                            name="amount"
                            defaultChecked
                            id={"a"+recomendSum+type}
                            defaultValue={recomendSum}
                            onChange={onRadioSelect}
                            />
                        <label htmlFor={'a'+recomendSum+type} >
                            {recomendSum} р
                        </label>
                    </div>

                    <div className='payment__custom-wrap'>
                        <div className='amout-radio__wrap amout-radio__wrap--custom'>
                            <input type="radio" 
                                name="amount"
                                id={"custom_"+type}
                                defaultValue="custom"
                                onChange={onRadioSelect}
                                />
                            <label htmlFor={"custom_"+type} >
                                Другая сумма
                            </label>
                        </div>
                    </div>
                { payAmount === 'custom' &&
                    <input placeholder='Введите сумму' type="number" value={customSum} className="payment__amount-number" onChange={customSumChangeHandler}/>
                }
            </div>
            </div>
            <div className='payment__submit-wrap'>
                { isLoading ?
                <p>Загрузка кода...</p>
                :
                <input className='payment__submit-btn' type="submit" value={"Оплатить"} />
                }
            </div>
                <p className="payment__privacy-text">
                    Нажимая на кнопку, вы соглашаетесь с <a href="https://yescoding.ru/personal-data" rel="noreferrer" target="_blank">Условиями обработки персональных данных</a>, а также с <a rel="noreferrer" target="_blank" href="https://yescoding.ru/oferta">Офертой</a>
                </p>
        </form>
    )
}

export default Sbp;